<template>
  <div class="medicinal-detail-box">
    <c-card
      class="mb12 br6"
    >
      <div class="flex-between-center">
        <div class="flex-start-center mb8">
          <van-image
            width="40"
            height="40"
            class="mr12"
            round
            :src="recipeInfo.sex === 1 ? require('@/assets/images/patient_m.png') : require('@/assets/images/patient_w.png')"
          ></van-image>
          <span class="fs14 fw700">
            <span class="mr6">{{ recipeInfo.patientName }}</span>
            <span class="mr6">{{ recipeInfo.sexName }}</span>
            <span>{{ recipeInfo.age || '-' }}岁</span>
          </span>
        </div>
        <div class="c999">{{ recipeInfo.createTime }}</div>
      </div>
      <div class="mb4 c999">诊断：{{ recipeInfo.diagnosis }}</div>
      <div class="mb4 c999 van-multi-ellipsis--l2">现病史：{{ recipeInfo.doctorAdvice }}</div>
      <div class="mb4 c999 van-multi-ellipsis--l2">过敏史：{{ recipeInfo.allergicHistory }}</div>
      <div class="mb4 c999">科室：{{ recipeInfo.deptName }}</div>
      <div class="mb4 c999">
        <span>处方医生：{{ recipeInfo.doctorName }}</span>
      </div>
    </c-card>

    <van-cell class="br6 ovh mb12" title="处方内容">
      <a
        v-for="(pic, index) in recipeInfo.uploadList"
        :key="pic.id"
        :href="pic.path"
        target="_blank"
        class="mr12"
      >
        处方{{ index + 1 }}
      </a>
    </van-cell>

    <c-card class="br6 mb12">
      <template #header>
        <div class="flex-between-center">
          <div class="fs16 fw700 c333">处方药品详情</div>
        </div>
      </template>
      <div
        v-for="(item, index) in drugList"
        :key="item.drugName"
        class="medicinal-item"
      >
        <div class="flex-between-center mb8">
          <span class="fs14 fw700 c333">{{ item.drugName }}</span>
          <span class="fs14 c999">¥{{ (item.price / 100.0).toFixed(2) }}</span>
        </div>

        <van-row gutter="10" class="mb4">
          <van-col span="12" class="c666">发药数量：{{ item.count }}{{ item.drugUnitStr }}</van-col>
          <van-col span="12" class="c666">单次剂量：{{ item.dose }}{{ item.doseUnitStr }}</van-col>
        </van-row>
        <van-row gutter="10" class="mb4">
          <van-col span="12" class="c666">给药方式：{{ item.giveTypeStr }}</van-col>
        </van-row>
        <van-row gutter="10" class="mb4">
          <van-col span="12" class="c666">频率：{{ item.frequencyCodeStr }}</van-col>
          <van-col span="12" class="c666">用药天数：{{ item.takeDays }}</van-col>
        </van-row>
        <van-divider v-if="index + 1 !== drugList.length" style="padding: 6px 0"></van-divider>
      </div>
    </c-card>

    <c-detail
      v-if="[3, 4].includes(recipeInfo.recipeStatus)"
      :data="recipeInfo"
      :columns="[
        { label: '处方状态', value: recipeInfo.recipeStatus === 3 ? '已驳回' : '已通过' },
        ...([3].includes(recipeInfo.recipeStatus) ? [
          { label: '驳回原因', prop: 'rejectedReason' }
        ] : []),
      ]"
    >
    </c-detail>

    <c-sticky
      v-if="recipeInfo.recipeStatus === 2"
      :btns="[
        { text: '驳回', type: 'danger', click: showReject },
        { text: '通过', click: onPass },
      ]"
    ></c-sticky>

    <c-dialog
      :dialog="dialog"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <van-field
        maxlength="300"
        show-word-limit
        type="textarea"
        placeholder="请输入驳回原因"
        rows="6"
        :border="false"
        v-model="rejectedReason"
      ></van-field>
    </c-dialog>
  </div>
</template>
<script>
import cCard from '@/components/cCard.vue';
import CSticky from '@/components/cSticky.vue';
import CDialog from '@/components/cDialog.vue';
import CDetail from '@/components/cDetail.vue';

export default {
  components: { cCard, CSticky, CDialog, CDetail },
  data() {
    return {
      recipeId: this.$route.query.recipeId,
      drugList: [],
      recipeInfo: {},
      dialog: {
        visible: false,
      },
      rejectedReason: '',
    };
  },
  methods: {
    onCancel() {
      this.dialog.visible = false;
    },
    onConfirm() {
      if (!this.rejectedReason) {
        this.$toast('请输入驳回原因');
        return;
      }
      const params = {
        recipeStatus: 3,
        ids: [this.recipeId],
        rejectedReason: this.rejectedReason,
      };
      this.$post('/api/blade-consult/drugDoctorWork/audit', params)
        .then(() => {
          this.$toast.success('驳回通过');
          this.onCancel();
          this.$router.back();
        });
    },
    showReject() {
      this.dialog = {
        visible: true,
        title: '驳回原因',
      };
    },
    onPass() {
      this.$dialog.confirm({
        message: '确认通过审核吗?',
      }).then(() => {
        const params = {
          recipeStatus: 4,
          ids: [this.recipeId],
        };
        this.$post('/api/blade-consult/drugDoctorWork/audit', params)
          .then(() => {
            this.$toast.success('操作成功');
            this.$router.back();
          });
      });
    },
    getMedicinalDetail() {
      this.$get(`/api/blade-consult/recipe/getRecipe/${this.recipeId}`)
        .then(({ data }) => {
          this.drugList = data.recipeDrugList || [];
          this.recipeInfo = data || {};
        });
    },
  },
  created() {
    this.getMedicinalDetail();
  },
};
</script>
